.cross-fade-leave {
  opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.cross-fade-height {
  transition: height 0.5s ease-in-out;
}

.complete-check-entry {
  animation: entry-with-rotation 0.2s; /* IE 10+, Fx 29+ */
  -webkit-animation: entry-with-rotation 0.2s; /* Safari 4+ */
  -moz-animation: entry-with-rotation 0.2s; /* Fx 5+ */
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards; /* Safari 4+ */
  -moz-animation-fill-mode: forwards; /* Fx 5+ */
}

@keyframes entry-with-rotation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes entry-with-rotation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes entry-with-rotation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
